exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-advice-tsx": () => import("./../../../src/pages/advice.tsx" /* webpackChunkName: "component---src-pages-advice-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-posts-04-snow-day-mdx": () => import("./../../../src/posts/04-snow-day.mdx" /* webpackChunkName: "component---src-posts-04-snow-day-mdx" */),
  "component---src-posts-05-pricing-psychology-mdx": () => import("./../../../src/posts/05-pricing-psychology.mdx" /* webpackChunkName: "component---src-posts-05-pricing-psychology-mdx" */),
  "component---src-posts-06-the-great-divergence-mdx": () => import("./../../../src/posts/06-the-great-divergence.mdx" /* webpackChunkName: "component---src-posts-06-the-great-divergence-mdx" */),
  "component---src-templates-standard-index-tsx": () => import("./../../../src/templates/Standard/index.tsx" /* webpackChunkName: "component---src-templates-standard-index-tsx" */)
}

